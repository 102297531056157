import { useRef, useState } from 'react';
import { Configure } from 'react-instantsearch';
import { useRouter } from 'next/router';

import { ChevronLeft, Close, CloseIcon, Filter } from '@/icons';
import { Settings } from '@/icons/Settings';
import { Container, Text } from '@/atoms';
import { Button } from '@/molecules';
import { Breadcrumbs } from '@/organisms';

import {
  ButtonHits,
  ClearRefinements,
  GridCardHits,
  Pagination,
  RangeSlider,
  RefinementList,
  SearchBox,
  SortBy,
  Title,
} from '@/components/algolia';
import { useFixSafariPortal } from '@/lib/hooks';

import type { FunctionComponent } from 'react';
import type { TypeListOfProductsProps } from './types';

/**
 * ListOfProducts
 */
export const ListOfProducts: FunctionComponent<TypeListOfProductsProps> = ({
  filtrableFields,
  filters,
  categoryAtribute,
  userId,
  url,
  title,
  description,
  type,
  query,
  breadcrumbs,
  showFilters = true,
  onClose,
  showTitleAsH1,
}: TypeListOfProductsProps) => {
  const [openFilters, setOpenFilters] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useFixSafariPortal(openFilters);

  const router = useRouter();

  return (
    <>
      <Configure
        hitsPerPage={24}
        {...(filters && { filters: filters })}
        {...(query && { query: query })}
        userToken={userId || undefined}
        clickAnalytics={true}
      />

      <Container wrapper as="section" ref={ref} zIndex="auto">
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="flex flex-col gap-3 lg:hidden">
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            )}

            <div className="flex flex-row items-start justify-between lg:!hidden">
              <div>
                <p className="u-headline u-headline--h2">
                  <Title title={title} />
                </p>

                {description && (
                  <p className="u-body u-body--s mt-3 max-w-md">
                    {description}
                  </p>
                )}
              </div>

              {onClose && (
                <Close onClick={onClose} className="cursor-pointer" />
              )}
            </div>
          </div>

          {showFilters && (
            <>
              <div className="flex flex-row gap-4 lg:!hidden">
                <Button
                  variant={openFilters ? 'primary' : 'secondary'}
                  onClick={() => setOpenFilters(!openFilters)}
                >
                  Filtros
                  <Filter width={14} height={14} />
                </Button>

                <SortBy
                  items={[
                    {
                      value: 'sortBy_price_asc',
                      label: 'Precio asc.',
                    },
                    {
                      value: 'sortBy_price_desc',
                      label: 'Precio desc.',
                    },
                    {
                      value: 'sortBy_name_asc',
                      label: 'Nombre asc.',
                    },
                    {
                      value: 'sortBy_name_desc',
                      label: 'Nombre desc.',
                    },
                  ]}
                  label="Ordenar por"
                />
              </div>

              <div
                className="group hidden px-4 pb-20 pt-[72px] open:fixed open:inset-0 open:z-50 open:block open:overflow-y-auto open:bg-white lg:static lg:block lg:w-64 lg:shrink-0 lg:py-4"
                // Custom HTML attribute to handle the open state, used in native elements like details
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                open={openFilters}
              >
                <div className="relative group-open:lg:pb-16 group-open:lg:pt-14">
                  <div className="hidden group-open:fixed group-open:left-0 group-open:right-0 group-open:top-0 group-open:z-20 group-open:flex group-open:items-center group-open:justify-between group-open:gap-6 group-open:bg-primary-900 group-open:p-4 group-open:text-white group-open:lg:absolute group-open:lg:-mx-4 group-open:lg:-mt-4">
                    <div className="flex select-none gap-4 uppercase">
                      <Settings />
                      Filtros
                    </div>

                    <CloseIcon
                      width={24}
                      height={24}
                      onClick={() => setOpenFilters(!openFilters)}
                      className="cursor-pointer"
                      color="currentColor"
                    />
                  </div>

                  {type === 'category' && (
                    <SearchBox
                      label="Buscar Producto"
                      className="mb-6"
                      id="category-search"
                    />
                  )}

                  <div className="u-headline u-headline--h3 mb-6 flex flex-row items-center justify-between">
                    Filtrar por
                    <ClearRefinements />
                  </div>

                  {/* Sidebar filters */}
                  {filtrableFields?.map((filtrableField) => {
                    // Checkbox-type filters
                    if (filtrableField.type === 'RefinementList') {
                      return (
                        <RefinementList
                          key={filtrableField.seo}
                          title={filtrableField.title}
                          attribute={
                            filtrableField.seo === 'Categoria' &&
                            categoryAtribute
                              ? categoryAtribute
                              : (filtrableField.seo ?? '')
                          }
                          filtrableFieldValues={filtrableField.values}
                          sortBy={['count:desc', 'name:asc']}
                          showMore
                          showMoreLimit={
                            (filtrableField.values?.length ?? 200) + 10
                          }
                        />
                      );
                    }

                    // Slider-type filters
                    if (filtrableField.type === 'RangeSlider') {
                      return (
                        <RangeSlider
                          key={filtrableField.title}
                          title={filtrableField.title}
                          attribute={filtrableField.attribute}
                        />
                      );
                    }
                  })}

                  <div className="z-20 hidden group-open:fixed group-open:bottom-0 group-open:left-0 group-open:right-0 group-open:block group-open:border-t group-open:border-primary-100 group-open:bg-white group-open:p-4 group-open:lg:absolute group-open:lg:-mx-4 group-open:lg:-mb-4">
                    <ButtonHits
                      size="normal-full"
                      onClick={() => setOpenFilters(!openFilters)}
                    >
                      {(nbHits) =>
                        `mostrar ${nbHits} ${
                          nbHits === 1 ? 'resultado' : 'resultados'
                        }`
                      }
                    </ButtonHits>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="w-full">
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumbs
                breadcrumbs={breadcrumbs}
                className="max-lg:hidden"
              />
            )}

            <div className="mb-8 hidden flex-col gap-4 lg:mt-4 lg:flex lg:flex-row lg:items-center lg:justify-between">
              <div>
                {onClose && (
                  <span
                    className="group u-label u-label--s mb-2 flex items-center gap-0.5"
                    role="button"
                    onClick={onClose}
                  >
                    <ChevronLeft
                      width={16}
                      className="transition-transform group-hover:-translate-x-0.5"
                    />
                    Volver
                  </span>
                )}

                <Text
                  as={
                    (router && router?.pathname === '/[...categories]') ||
                    showTitleAsH1
                      ? 'h1'
                      : 'h2'
                  }
                  className="u-headline u-headline--h2"
                >
                  <Title title={title} />
                </Text>

                {description && (
                  <p className="u-body u-body--s mt-3 max-w-md">
                    {description}
                  </p>
                )}
              </div>

              <SortBy
                items={[
                  {
                    value: 'sortBy_price_asc',
                    label: 'Precio asc.',
                  },
                  {
                    value: 'sortBy_price_desc',
                    label: 'Precio desc.',
                  },
                  {
                    value: 'sortBy_name_asc',
                    label: 'Nombre asc.',
                  },
                  {
                    value: 'sortBy_name_desc',
                    label: 'Nombre desc.',
                  },
                ]}
                label="Ordenar por"
                className="self-end"
              />
            </div>

            <GridCardHits
              className="mb-4 xs:mx-auto xs:max-w-[465px] sm:mx-0 sm:max-w-none"
              itemListId={url}
              itemListName={url}
              {...(!showFilters && {
                lg: 5,
                xl: 5,
              })}
            />

            <Pagination
              className="w-full items-center justify-end"
              parentRef={ref}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

ListOfProducts.displayName = 'ListOfProducts';
